import React, { useEffect, useRef, useState } from "react";
import { Button, FloatingLabel, Form } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { PatternFormat } from "react-number-format";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { maxBounds, Patterns } from "../../constants/contants";
import { Eye, EyeHide, ToastCrossIcon } from "../../constants/svg";
import { setAuthData } from "../../store/slices/authSlice";
import { toastMessage } from "../../utils/helper";
import { notification } from "antd";
import { CheckRegistrationValidation } from "../../services/auth/auth";

function AccountSetup(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [disable, setDisable] = useState(false);
  const [disableIsValid, setDisableIsValid] = useState(false);
  const [passwordShow, setPasswordShow] = useState(false);
  const [cPasswordShow, setCPasswordShow] = useState(false);
  const {
    register,
    watch,
    handleSubmit,
    reset,
    control,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
    criteriaMode: "firstError",
    shouldFocusError: true,
  });
  const passwordWatch = useRef({});
  passwordWatch.current = watch("password", "");
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  const onSubmit = async (data) => {
    try {
      setDisable(true);
      const fullName = data.name.split(" ").filter((elem) => elem != "");
      if (fullName.length <= 1) {
        toastMessage("warning", "Please provide Full Name.");
        return;
      }
      // data.phone = "+1" + data.phone
      data.phone =
        data?.phone && data?.phone.slice(0, 2) == "+1"
          ? data.phone
          : "+1" + data.phone;
      const response = await CheckRegistrationValidation(data);
      const { message, status } = response.data;
      if (!status) {
        notification.error({
          description: message,
          placement: "bottomRight",
          className: "createdNotification",
          closeIcon: <ToastCrossIcon />,
        });
      } else {
        setDisable(false);
        dispatch(setAuthData({ ...auth.formData, ...data }));
        navigate("/business-details");
      }
    } catch (e) {
      toastMessage("error", e.message);
    } finally {
      setDisable(false);
    }
  };

  useEffect(() => {
    reset({
      name: auth?.formData?.name,
      phone:
        auth?.formData?.phone && auth?.formData?.phone.slice(0, 2) == "+1"
          ? auth?.formData?.phone.substring(2)
          : auth?.formData?.phone,
      email: auth?.formData?.email,
      password: auth?.formData?.password,
      password_confirmation: auth?.formData?.password_confirmation,
    });
  }, [auth?.formData]);

  useEffect(() => {
    passwordWatch.current = watch("password", "");
  }, [watch("password")]);

  useEffect(() => {
    let field1 = watch("name");
    let field2 = watch("email");
    let field3 = watch("phone");
    let field4 = watch("password");
    let field5 = watch("password_confirmation");
    if (
      field1 &&
      field2 &&
      field3 &&
      field4 &&
      field5 &&
      Object.keys(errors).length == 0
    ) {
      setDisableIsValid(true);
    } else {
      setDisableIsValid(false);
    }
  }, [Object.keys(errors).length]);

  return (
    <>
      <div className="authBox accountSetup">
        <div className="authHeaderText">
          <h1>Account Setup</h1>
          <p>Start your 14-day free trial and unlock everything!</p>
        </div>
        <Form onSubmit={handleSubmit(onSubmit)} autoComplete={"off"}>
          <FloatingLabel
            controlId="floatingName"
            label="Full Name"
            className="mb-20"
          >
            <Form.Control
              type="text"
              placeholder="Full name"
              autoComplete="off"
              role={"presentation"}
              className={errors.name ? "hasError" : ""}
              maxLength={50}
              {...register("name", {
                required: {
                  value: true,
                  // message: VALIDATIONS_TEXT.NAME_REQUIRED,
                },
                pattern: {
                  value: Patterns.Name,
                  // message: VALIDATIONS_TEXT.NAME_PATTERN,
                },
                maxLength: {
                  value: 50,
                  // message: VALIDATIONS_TEXT.NAME_MAX,
                },
              })}
            />
          </FloatingLabel>
          <Controller
            control={control}
            defaultValue={""}
            name="phone"
            rules={{
              required: {
                value: true,
                message: "Phone is required",
              },
              pattern: {
                value:
                  /^(?:(?:\+1)|(?:1))?[ -]?\(?([2-9][0-9]{2})\)?[ -]?([2-9][0-9]{2})[ -]?([0-9]{4})$/,
              },
            }}
            render={({ field }) => (
              <FloatingLabel
                controlId="floatingPhone"
                label="Phone Number"
                className="mb-20"
              >
                <PatternFormat
                  value={field.value}
                  onValueChange={(values) => {
                    field.onChange(values.value);
                  }}
                  allowEmptyFormatting
                  placeholder="Phone Number"
                  format="+1 ###-###-####"
                  className={
                    errors.phoneNumber
                      ? "hasError form-control"
                      : "form-control"
                  }
                />
              </FloatingLabel>
            )}
          />
          <FloatingLabel
            controlId="floatingEmail"
            label="Email Address"
            className="mb-20"
          >
            <Form.Control
              type="email"
              placeholder="name@example.com"
              autoComplete="off"
              className={errors.email ? "hasError" : ""}
              {...register("email", {
                required: {
                  value: true,
                  // message: VALIDATIONS_TEXT.EMAIL_REQUIRED,
                },
                pattern: {
                  value: Patterns.Email,
                  // message: VALIDATIONS_TEXT.EMAIL_PATTERN,
                },
                maxLength: {
                  value: maxBounds.EMAIL,
                  // message: VALIDATIONS_TEXT.EMAIL_MAX,
                },
              })}
            />
          </FloatingLabel>
          <Form.Group className="themeFormGroup">
            <FloatingLabel
              controlId="password"
              label="Create Password"
              className="mb-20 position-relative"
            >
              <Form.Control
                type={passwordShow ? "text" : "password"}
                placeholder="xxxxxx"
                aria-describedby="passToggle"
                className={errors.password ? "hasError" : ""}
                minLength={6}
                maxLength={16}
                autoComplete="off"
                role={"presentation"}
                {...register("password", {
                  required: {
                    value: true,
                    // message: VALIDATIONS_TEXT.PASSWORD_REQUIRED,
                  },
                  pattern: {
                    value: Patterns.Password,
                    // message: VALIDATIONS_TEXT.PASSWORD_PATTERN,
                  },
                  minLength: {
                    value: 6,
                    // message: VALIDATIONS_TEXT.PASSWORD_MIN,
                  },
                  maxLength: {
                    value: 16,
                    // message: VALIDATIONS_TEXT.PASSWORD_MAX,
                  },
                })}
              />
              <div
                className="password-toggle-icon"
                onClick={() => setPasswordShow(!passwordShow)}
              >
                {passwordShow ? <Eye /> : <EyeHide />}
              </div>
            </FloatingLabel>
          </Form.Group>
          <Form.Group className="themeFormGroup">
            <FloatingLabel
              controlId="password_confirmation"
              label="Confirm Password"
              className="mb-20 position-relative"
            >
              <Form.Control
                type={cPasswordShow ? "text" : "password"}
                placeholder="••••••••"
                aria-describedby="passToggle"
                minLength={6}
                maxLength={16}
                className={errors.password_confirmation ? "hasError" : ""}
                autoComplete="off"
                role={"presentation"}
                {...register("password_confirmation", {
                  required: {
                    value: true,
                    // message: VALIDATIONS_TEXT.PASSWORD_REQUIRED,
                  },
                  pattern: {
                    value: Patterns.Password,
                    // message: VALIDATIONS_TEXT.PASSWORD_PATTERN,
                  },
                  validate: (value) =>
                    value == passwordWatch.current ||
                    "The passwords do not match",
                  minLength: {
                    value: 6,
                    // message: VALIDATIONS_TEXT.PASSWORD_MIN,
                  },
                  maxLength: {
                    value: 16,
                    // message: VALIDATIONS_TEXT.PASSWORD_MAX,
                  },
                })}
              />
              <div
                className="password-toggle-icon"
                onClick={() => setCPasswordShow(!cPasswordShow)}
              >
                {cPasswordShow ? <Eye /> : <EyeHide />}
              </div>
            </FloatingLabel>
          </Form.Group>
          <Button
            type={"submit"}
            className={"w-100 btn-submit"}
            disabled={!isValid}
          >
            Next
          </Button>
        </Form>
      </div>
    </>
  );
}

export default AccountSetup;
